<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        THÔNG TIN CƠ BẢN
      </b-badge>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="mb-2 pb-2 pb-sm-0 mb-sm-0 border-bottom-xs"
        >
          <div
            class="same-height"
            data-id-same-height="contract-info-1"
          >
            <div class="detail-title d-flex">
              <div class="mr-2">
                Khách hàng
              </div>
              <div
                v-if="contract.customer && contract.customer.category"
                class="detail-text text-primary font-weight-bold"
              >
                <b-badge
                  :class="'bg-light-'+contract.customer.category + ' text-'+contract.customer.category"
                  size="sm"
                >
                  <feather-icon
                    icon="TagIcon"
                    size="16"
                    class="mr-25"
                  />
                  {{ $customerCategory.getName(contract.customer && contract.customer.category) }}
                </b-badge>
                <div v-if="contract.customer && contract.customer.category === 'company'">
                  MST: {{ contract.customer.tax_code }}
                </div>
              </div>
            </div>
            <!--Tên khách hàng-->
            <div class="d-flex mb-50">
              <div class="detail-label">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                />
              </div>
              <span
                class="detail-text text-primary font-weight-bold text-uppercase"
              >{{ contract.customer && contract.customer.name }}</span>
            </div>

            <!-- Địa chỉ -->
            <div class="d-flex mb-50">
              <div class="detail-label">
                <feather-icon
                  icon="MapPinIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.customer && contract.customer.address"
                class="detail-text text-primary font-weight-bold"
              >{{ contract.customer && contract.customer.address }}</span>
            </div>

            <!--Số điện thoại-->
            <div class="d-flex">
              <div class="detail-label">
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.customer && contract.customer.phone"
                class="detail-text text-primary font-weight-bold"
              >{{ contract.customer && contract.customer.phone }}</span>
            </div>
          </div>

          <div
            class="same-height"
            data-id-same-height="contract-info-2"
          >
            <div class="detail-title">
              Người đại diện pháp luật
            </div>
            <!--Người đại diện pháp luật-->
            <div class="d-flex mb-50">
              <div class="detail-label">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.customer && contract.customer.rep_name"

                class="detail-text text-primary font-weight-bold"
              >
                {{
                  (contract.customer && contract.customer.rep_gender || '') + ' ' + (contract.customer && contract.customer.rep_name)
                }}
                <div
                  v-if="contract.customer && contract.customer.rep_job_title"
                  class="text-secondary"
                >({{ contract.customer.rep_job_title }})</div>
              </span>
            </div>

            <!-- CCCD -->
            <div class="d-flex">
              <div class="detail-label">
                <feather-icon
                  icon="CreditCardIcon"
                  size="16"
                />
              </div>
              <div
                v-if="contract.customer && contract.customer.rep_card_id"
                class="detail-text font-weight-bold"
              >
                <span class="text-primary">{{ contract.customer && contract.customer.rep_card_id }}</span>
                <br>
                <div v-if="contract.customer.rep_card_date || contract.customer.rep_card_place">
                  <small>
                    ({{ contract.customer.rep_card_date }}
                    <template v-if="contract.customer.rep_card_place">{{ contract.customer.rep_card_place ? 'tại ' + contract.customer.rep_card_place : '' }}</template>)
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div
            class="same-height"
            data-id-same-height="contract-info-3"
          >
            <div class="detail-title">
              Người liên hệ
            </div>
            <!--Người liên hệ-->
            <div
              v-if="contract.customer && contract.customer.contact_name"
              class="d-flex mb-50"
            >
              <div class="detail-label">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.customer && contract.customer.contact_name"
                class="group-text text-primary font-weight-bold"
              >{{ contract.customer && contract.customer.contact_name }}</span>
            </div>

            <!--Số điện thoại-->
            <div class="d-flex mb-50">
              <div class="detail-label">
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.customer && contract.customer.contact_phone"
                class="detail-text text-primary font-weight-bold"
              >{{ contract.customer && contract.customer.contact_phone }}</span>
            </div>

            <!--Email-->
            <div class="d-flex">
              <div class="detail-label">
                <feather-icon
                  icon="MailIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.customer && contract.customer.contact_email"
                class="detail-text text-primary font-weight-bold"
              >{{ contract.customer && contract.customer.contact_email }}</span>
            </div>
          </div>

          <div class="detail-title">
            Thông tin thêm
          </div>
          {{ contract.customer && contract.customer.more || NO_UPDATE_TEXT }}
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <div
            class="same-height"
            data-id-same-height="contract-info-1"
          >
            <div class="detail-title d-flex">
              <div class="mr-2">
                Khách nợ
              </div>
              <div
                v-if="contract.debtor && contract.debtor.category"
                class="detail-text text-primary font-weight-bold"
              >
                <b-badge
                  :class="'bg-light-'+contract.debtor.category + ' text-'+contract.debtor.category"
                  size="sm"
                >
                  <feather-icon
                    icon="TagIcon"
                    size="16"
                    class="mr-25"
                  />
                  {{ $customerCategory.getName(contract.debtor && contract.debtor.category) }}
                </b-badge>
                <div v-if="contract.debtor && contract.debtor.category === 'company'">
                  MST: {{ contract.debtor.tax_code }}
                </div>
              </div>
            </div>
            <!--Tên khách nợ-->
            <div class="d-flex mb-50">
              <div class="detail-label">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                />
              </div>
              <span
                class="detail-text text-primary font-weight-bold text-uppercase"
              >{{ contract.debtor && contract.debtor.name }}</span>
            </div>

            <!-- Địa chỉ -->
            <div class="d-flex mb-50">
              <div class="detail-label">
                <feather-icon
                  icon="MapPinIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.debtor && contract.debtor.address"
                class="detail-text text-primary font-weight-bold"
              >{{ contract.debtor.address }}</span>
            </div>

            <!--Số điện thoại-->
            <div class="d-flex">
              <div class="detail-label">
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.debtor && contract.debtor.phone"
                class="detail-text text-primary font-weight-bold"
              >{{ contract.debtor.phone }}</span>
            </div>
          </div>

          <div
            class="same-height"
            data-id-same-height="contract-info-2"
          >
            <div
              class="detail-title"
            >
              Người đại diện pháp luật
            </div>
            <!--Người đại diện pháp luật-->
            <div class="d-flex mb-50">
              <div class="detail-label">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.debtor && contract.debtor.rep_name"
                class="detail-text text-primary font-weight-bold"
              >
                {{ (contract.debtor && contract.debtor.rep_gender || '') + ' ' + (contract.debtor && contract.debtor.rep_name) }}
                <div
                  v-if="contract.debtor && contract.debtor.rep_job_title"
                  class="text-secondary"
                >({{ contract.debtor && contract.debtor.rep_job_title }})</div>
              </span>
            </div>

            <!-- CCCD -->
            <div class="d-flex">
              <div class="detail-label">
                <feather-icon
                  icon="CreditCardIcon"
                  size="16"
                />
              </div>
              <div
                v-if="contract.debtor && contract.debtor.rep_card_id"
                class="detail-text font-weight-bold"
              >
                <span class="text-primary">{{ contract.debtor.rep_card_id }}</span>
                <br>
                <div v-if="contract.debtor.rep_card_date || contract.debtor.rep_card_place">
                  <small>
                    ({{ contract.debtor.rep_card_date }}
                    <template v-if="contract.debtor.rep_card_place">{{ contract.debtor.rep_card_place ? 'tại ' + contract.debtor.rep_card_place : '' }}</template>)
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div
            class="same-height"
            data-id-same-height="contract-info-3"
          >
            <div class="detail-title">
              Người liên hệ
            </div>
            <!--Người liên hệ-->
            <div
              v-if="contract.debtor && contract.debtor.contact_name"
              class="d-flex mb-50"
            >
              <div class="detail-label">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.debtor && contract.debtor.contact_name"
                class="group-text text-primary font-weight-bold"
              >{{ contract.debtor && contract.debtor.contact_name }}</span>
            </div>

            <!--Số điện thoại-->
            <div
              class="d-flex mb-50"
            >
              <div class="detail-label">
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.debtor && contract.debtor.contact_phone"
                class="detail-text text-primary font-weight-bold"
              >{{ contract.debtor && contract.debtor.contact_phone }}</span>
            </div>

            <!--Email-->
            <div class="d-flex">
              <div class="detail-label">
                <feather-icon
                  icon="MailIcon"
                  size="16"
                />
              </div>
              <span
                v-if="contract.debtor && contract.debtor.contact_email"
                class="detail-text text-primary font-weight-bold"
              >{{ contract.debtor && contract.debtor.contact_email }}</span>
            </div>
          </div>

          <div class="detail-title">
            Thông tin thêm
          </div>
          {{ contract.debtor && contract.debtor.more || NO_UPDATE_TEXT }}
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          <div class="text-primary">
            <span class="text-danger">*</span>
            Khách Nợ
            <strong
              v-if="contract && contract.is_calculate_team_fund"
              class="text-danger"
            >có ở</strong>
            <strong
              v-else
              class="text-danger"
            >không ở</strong>
            nội thành <strong>Hồ Chí Minh</strong>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, BCol, BRow, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
  updated() {
    this.setSameHeight()
  },
  methods: {
    setSameHeight() {
      const arr = this.$el.querySelectorAll('.same-height')
      if (window.innerWidth < 768 || !arr.length) return
      const objHeight = {}
      arr.forEach(item => {
        const a = item.getAttribute('data-id-same-height')
        const h = item.clientHeight
        if (!objHeight[a]) {
          objHeight[a] = h
        } else {
          objHeight[a] = objHeight[a] > h ? objHeight[a] : h
        }
      })

      arr.forEach(item => {
        const a = item.getAttribute('data-id-same-height')
        // eslint-disable-next-line no-param-reassign
        item.style.height = `${objHeight[a] + 1}px`
      })
    },
  },
}
</script>
