<template>
  <div>
    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header
        class="cursor-pointer user-select-none"
        :class="isShowDebtCollection ? '' : 'bg-collapsed-header'"
        @click="isShowDebtCollection = !isShowDebtCollection"
      >
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN THU NỢ
        </b-badge>
        <div
          v-if="debtCollectionOverview"
          class="p-1 flex-grow-1"
        >
          <b-progress
            max="100"
          >
            <b-progress-bar :value="debtCollectionOverview.percent">
              <strong>{{ debtCollectionOverview.percent }} %</strong>
            </b-progress-bar>
          </b-progress>
        </div>
        <feather-icon
          :icon="isShowDebtCollection ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="18"
          class="cursor-pointer"
        />
      </b-card-header>
      <b-card-body
        v-show="isShowDebtCollection"
      >
        <div class="debt-collection-wrapper">
          <div class="dc-top">
            <b-row
              v-if="debtCollectionOverview && debtCollectionOverview.percent"
              class="flex-grow-1 mb-1"
            >
              <b-col
                cols="12"
                md="4"
              >
                <div class="d-flex gap-50">
                  <span class="minw-125">Giá trị nợ:</span><br>
                  <span class="text-primary font-weight-bolder">
                    {{ formatNumber(totalAmountToBeCollected) }}<sup>đ</sup>
                  </span>
                </div>
                <div class="d-flex gap-50">
                  <span class="minw-125">Còn phải thu:</span><br>
                  <span class="text-primary font-weight-bolder">
                    {{
                      formatNumber(Math.max(parseFloat(contract.original_amount) + parseFloat(contract.interest_amount) - parseFloat(debtCollectionOverview.totalDebtAmount), 0))
                    }}<sup>đ</sup>
                  </span>
                  <!--                  <span class="font-weight-bolder">({{ Math.round(100 - debtCollectionOverview.percent) }} %)</span>-->
                </div>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <div class="d-flex gap-50">
                  <span class="minw-125">Tổng tiền thu được:</span><br>
                  <span class="text-primary font-weight-bolder">
                    {{ formatNumber(Math.max(debtCollectionOverview.totalDebtAmount, 0)) }}<sup>đ</sup>
                  </span>
                  <!--                  <span class="font-weight-bolder">({{ Math.max(Math.round(debtCollectionOverview.percent), 0) }} %)</span>-->
                </div>
                <div class="d-flex gap-50">
                  <span class="minw-125">Nợ gốc thu được:</span><br>
                  <span class="text-primary font-weight-bolder">
                    {{ formatNumber(Math.max(debtCollectionOverview.totalDebtAmountOrigin, 0)) }}<sup>đ</sup>
                  </span>
                  <!--                  <span class="font-weight-bolder">({{ Math.max(Math.round(debtCollectionOverview.percent), 0) }} %)</span>-->
                </div>
                <div class="d-flex gap-50">
                  <span class="minw-125">Nợ lãi thu được:</span><br>
                  <span class="text-primary font-weight-bolder">
                    {{ formatNumber(Math.max(debtCollectionOverview.totalDebtAmountInterest, 0)) }}<sup>đ</sup>
                  </span>
                  <!--                  <span class="font-weight-bolder">({{ Math.max(Math.round(debtCollectionOverview.percent), 0) }} %)</span>-->
                </div>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <div class="d-flex gap-50">
                  <span class="minw-125">Tổng KH nhận:</span><br>
                  <span class="text-primary font-weight-bolder">
                    {{ formatNumber(totalCustomerCollected) }}<sup>đ</sup>
                  </span>
                </div>
                <div class="d-flex gap-50">
                  <span class="minw-125">Tổng SL nhận:</span><br>
                  <span class="text-primary font-weight-bolder">
                    {{ formatNumber(totalSongLongCollected) }}<sup>đ</sup>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-button
              v-if="hasAbilityUpdateDebt"
              variant="primary"
              class="text-nowrap zindex-1"
              size="sm"
              @click="openModalAddDebtCollection"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
              Thêm
            </b-button>
          </div>
          <div class="dc-content">
            <b-table
              ref="refUsersListTable"
              class="position-relative"
              responsive
              hover
              small
              bordered
              primary-key="id"
              show-empty
              empty-text="Không có kết quả nào"
              :items="debtTableData"
              :fields="tableColumns"
              @row-clicked="openModalDebtCollectionDetail"
            >
              <template #cell(ngay-thu)="data">
                <span
                  v-if="data.item['is_send_sms']"
                  class="sms-ticket text-danger"
                >
                  <span>SMS</span>
                  <feather-icon
                    icon="CheckIcon"
                  />
                </span>
                <span class="text-primary text-nowrap">{{ data.item['ngay-thu'] }}</span>
              </template>
              <template #cell(thu-duoc)="data">
                <span class="text-primary text-nowrap">{{ formatNumber(data.item['thu-duoc']) }} đ</span>
              </template>
              <template #cell(phi-dv)="data">
                <span class="text-nowrap">{{ data.item['phi-dv'] }}%</span>
              </template>
              <template #cell(tong-pdv)="data">
                <span class="text-nowrap">{{ formatNumber(data.item['tong-pdv']) }} đ</span>
              </template>
              <template #cell(chi-doi)="data">
                <span class="text-primary text-nowrap">{{ formatNumber(data.item['chi-doi']) }} đ</span>
              </template>
              <template #cell(cong-doan)="data">
                <span class="text-nowrap">{{ formatNumber(data.item['cong-doan']) }} đ</span>
              </template>
              <template #cell(lai-xe)="data">
                <span class="text-nowrap">{{ formatNumber(data.item['lai-xe']) }} đ</span>
              </template>
              <template #cell(tra-lai-lai-xe)="data">
                <span class="text-nowrap">{{ formatNumber(data.item['tra-lai-lai-xe']) }} đ</span>
              </template>
              <template #cell(giu-lai)="data">
                <span class="text-nowrap">{{ formatNumber(data.item['giu-lai']) }} đ</span>
              </template>
              <template #cell(quy-doi)="data">
                <span class="text-nowrap">{{ formatNumber(data.item['quy-doi']) }} đ</span>
              </template>
              <template #cell(95-chi-doi)="data">
                <span class="text-primary text-nowrap">{{ formatNumber(data.item['95-chi-doi']) }} đ</span>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  class="dropdown-align-right"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    <div
                      class="text-primary"
                      @click="openModalConfirmSendSMS(data.item)"
                    >
                      <feather-icon
                        icon="SendIcon"
                      />
                      Gửi SMS
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <div
                      @click="openModalEditDebtCollection(data.item)"
                    >
                      <feather-icon icon="EditIcon" />
                      Sửa
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <div
                      class="text-danger"
                      @click="e => openModalConfirmDeleteDebtCollection(data.item, e)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                      Xóa
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      id="modal-debt-collection"
      ref="modal-debt-collection"
      cancel-title="Hủy"
      :ok-title="modalMode === 'create' ? 'Thêm' : 'Sửa'"
      centered
      :title="(modalMode === 'create' ? 'Thêm' : 'Chỉnh sửa') + ' số tiền thu nợ cho ' + (contract.contract_code ? ' Hợp Đồng: ' : ' Hồ Sơ: ') + (contract.contract_code || contract.document_code)"
      @ok="handleOk"
    >
      <b-card-text>
        <b-form-group
          label="Loại nợ"
        >
          <b-form-radio-group
            id="radio-group-1"
            v-model="debtCollectionType"
            :options="[{value: 'origin', text: 'Nợ gốc'}, {value: 'interest', text: 'Nợ lãi'}]"
            name="debtCollectionType"
          />
        </b-form-group>
        <label for="debt-collection-value">Số tiền</label>
        <b-input-group class="input-group-merge">
          <cleave
            id="debt-collection-value"
            v-model="debtCollectionValue"
            class="form-control text-primary"
            :raw="true"
            :options="formatOptions.number"
            placeholder="Vui lòng nhập số tiền"
          />
          <b-input-group-append is-text>
            đ
          </b-input-group-append>
        </b-input-group>
        <br>
        <label for="debt-collection-fee">Phí dịch vụ</label>
        <b-input-group class="input-group-merge">
          <b-form-input
            id="debt-collection-fee"
            v-model="debtCollectionFeeService"
            class="text-primary"
            :formatter="v => $formatPercentMinMax(v)"
            type="number"
          />
          <b-input-group-append is-text>
            %
          </b-input-group-append>
        </b-input-group>
        <br>
        <label for="debt-collection-fee">Ngày thu</label>
        <flat-pickr
          v-model="collectAt"
          class="form-control"
          :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
        />
        <br>
        <label for="debt-collection-team_fund">Quỹ đội</label>
        <b-input-group class="input-group-merge">
          <cleave
            id="debt-collection-team_fund"
            v-model="debtCollectionTeamfund"
            class="form-control text-primary"
            :raw="true"
            :options="formatOptions.number"
            placeholder="Quỹ đội"
          />
          <b-input-group-append is-text>
            đ
          </b-input-group-append>
        </b-input-group>
        <br>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-debt-collection-confirm-send-sms"
      ref="modal-debt-collection-confirm-send-sms"
      cancel-title="Hủy"
      ok-title="Gửi"
      centered
      title="Xác nhận gửi SMS!"
      @ok="confirmSendSMS"
    >
      <b-card-text>
        <div>Xác nhận gửi <span class="text-danger">gửi SMS</span> về lần Thu Nợ ngày <span
          class="text-danger"
        >{{ formatDate(collectAt) }}</span>?
        </div>
        <br>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-debt-collection-detail"
      ref="modal-debt-collection-detail"
      ok-only
      ok-title="Ok"
      centered
      size="lg"
      header-text-variant="primary"
      :title="'Chi tiết doanh thu đội cho lần thu nợ ngày ' + formatDate(debtCollectionDetail.collect_at)"
    >
      <b-card-text>
        <br>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                sm="6"
              >
                <div>
                  <span class="debt-label">Số tiền thu được</span>
                  <span class="detail-text text-primary font-weight-bolder">{{
                    formatNumber(debtCollectionDetail.amount)
                  }}</span>
                </div>
                <div>
                  <span class="debt-label">Phí DV</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ parseFloat(debtCollectionDetail.fee_to_buy_original) }}%</span>
                </div>
                <div>
                  <span class="debt-label">Tổng PDV</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(debtCollectionDetail.amount_to_buy_original) }} đ</span>
                </div>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <div>
                  <span class="debt-label">Chi đội</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(debtCollectionDetail.amount_for_team) }} đ</span>
                </div>
                <div>
                  <span class="debt-label">Công đoàn</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(debtCollectionDetail.syndicate_amount) }} đ</span>
                </div>
                <div>
                  <span class="debt-label">Lái xe</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(debtCollectionDetail.driver_amount) }} đ</span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <br>
        <b-card no-body>
          <b-card-body>
            <b-table
              ref="refUsersListTable"
              class="position-relative"
              responsive
              small
              bordered
              primary-key="id"
              show-empty
              empty-text="Không có kết quả nào"
              :items="debtDetailTableData"
              :fields="debtDetailTableColumns"
            >
              <template #cell(ten)="data">
                <span class="text-primary text-nowrap">{{ data.item['ten'] }}</span>
              </template>
              <template #cell(so-tien)="data">
                <span class="text-primary text-nowrap">{{ formatNumber(data.item['so-tien']) }} đ</span>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
        <br>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-confirm-delete-debt-collection"
      ref="modal-confirm-delete-debt-collection"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa Thu nợ!"
      @ok="handleDeleteDebtCollection"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> Thu nợ
        <br>
        <br>
        - Ngày: <span class="text-primary font-weight-bolder">{{
          formatDate(currentDebt && currentDebt.collect_at)
        }}</span>
        <br>
        - Giá trị: <span class="text-primary font-weight-bolder">{{
          formatNumber(currentDebt && currentDebt.amount)
        }}</span>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BProgress,
  BProgressBar,
  BRow,
  BTable,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import { subject } from '@casl/ability'
import { ref, watch } from '@vue/composition-api'
import ability from '@/libs/acl/ability'
import flatPickr from 'vue-flatpickr-component'
import http from '@/global/http'

export default {
  components: {
    Cleave,
    BCard,
    BCardHeader,
    BBadge,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BProgress,
    BProgressBar,
    BTable,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormInput,
    flatPickr,
    BFormGroup,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
    debtCollectionList: {
      type: Array,
      default: () => [],
    },
    debtCollectionOverview: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  computed: {
    totalAmountToBeCollected() {
      return parseFloat(this.contract.original_amount) + parseFloat(this.contract.interest_amount)
    },
    totalCustomerCollected() {
      return this.debtCollectionList.reduce((t, c) => t + c.amount * ((100 - parseFloat(c.fee_to_buy_original)) / 100), 0)
    },
    totalSongLongCollected() {
      return this.debtCollectionList.reduce((t, c) => t + c.amount * (parseFloat(c.fee_to_buy_original) / 100), 0)
    },
    tableColumns() {
      const cols = [
        {
          label: 'STT',
          key: 'stt',
        },
        {
          label: 'Ngày thu',
          key: 'ngay-thu',
          stickyColumn: true,
        },
        {
          label: 'Đội',
          key: 'doi',
          tdClass: 'text-nowrap',
        },
        {
          label: 'Thu được',
          key: 'thu-duoc',
        },
        {
          label: 'Loại',
          key: 'loai-no',
          tdClass: 'text-nowrap',
        },
      ]
      if (this.hasAbilityUpdateDebt) {
        cols.push(
          {
            label: 'Phí DV',
            key: 'phi-dv',
          },
          {
            label: 'Tổng PDV',
            key: 'tong-pdv',
          },
          {
            label: 'Chi đội',
            key: 'chi-doi',
          },
          {
            label: 'Công đoàn 0.5%',
            key: 'cong-doan',
          },
          {
            label: 'Chi phí xe 1%',
            key: 'lai-xe',
          },
          {
            label: 'Chi phí xe 0.5% trả đội',
            key: 'tra-lai-lai-xe',
          },
          {
            label: 'Giữ lại 3%',
            key: 'giu-lai',
          },
          {
            label: 'Quỹ đội',
            key: 'quy-doi',
          },
          {
            label: '95% Chi đội',
            key: '95-chi-doi',
          },
          {
            label: '',
            key: 'actions',
          },
        )
      }
      return cols
    },
    hasAbilityUpdateDebt() {
      return this.ability()
        .can('update-debt', subject('Contract', this.contract))
    },
    debtDetailTableData() {
      const trans = this.debtCollectionDetail?.transactions || []
      return trans.map((t, idx) => ({
        ...t,
        stt: idx + 1,
        doi: t.department_name,
        ten: t.user.fullname,
        'chuc-vu': this.$userJobTitleOptions.find(u => u.value === t.user.job_title_code).label,
        'so-tien': t.value,
      }))
    },
    currentDebt() {
      if (!this.debtCollectionId || !this.debtCollectionList.length) return {}
      return this.debtCollectionList.find(d => d.id === this.debtCollectionId) || {}
    },
    debtTableData() {
      return this.debtCollectionList.map((d, idx) => ({
        ...d,
        stt: idx + 1,
        'ngay-thu': this.formatDate(d.collect_at),
        doi: d.department_name,
        'thu-duoc': d.amount,
        'loai-no': d.debt_type === 'origin' ? 'Nợ gốc' : 'Nợ lãi',
        'phi-dv': parseFloat(d.fee_to_buy_original),
        'tong-pdv': d.amount_to_buy_original,
        'chi-doi': d.amount_for_team,
        'cong-doan': d.syndicate_amount,
        'lai-xe': d.driver_amount,
        'tra-lai-lai-xe': d.return_driver_amount,
        'giu-lai': d.keep_amount,
        'quy-doi': d.team_fund,
        '95-chi-doi': d.final_amount_for_team,
      }))
    },
  },
  methods: {
    openModalConfirmDeleteDebtCollection(row, e) {
      e.stopPropagation()
      this.debtCollectionId = row.id
      this.$refs['modal-confirm-delete-debt-collection'].show()
    },
    openModalDebtCollectionDetail(row) {
      this.debtCollectionDetail = row
      this.$refs['modal-debt-collection-detail'].show()
    },
    confirmSendSMS() {
      const deb = this.debtTableData.find(d => d.id === this.debtCollectionId)
      this.$emit('send-deb-sms', deb)
    },
    openModalEditDebtCollection(row) {
      this.modalMode = 'edit'
      this.debtCollectionValue = row.amount
      this.debtCollectionFeeService = row.fee_to_buy_original
      this.debtCollectionTeamfund = row.team_fund
      this.debtCollectionId = row.id
      this.collectAt = row.collect_at
      this.$refs['modal-debt-collection'].show()
    },
    openModalConfirmSendSMS(row) {
      this.debtCollectionValue = row.amount
      this.debtCollectionFeeService = row.fee_to_buy_original
      this.debtCollectionTeamfund = row.team_fund
      this.debtCollectionId = row.id
      this.collectAt = row.collect_at
      this.$refs['modal-debt-collection-confirm-send-sms'].show()
    },
    openModalAddDebtCollection() {
      this.modalMode = 'create'
      this.debtCollectionValue = null
      this.debtCollectionFeeService = parseFloat(this.contract.fee_to_buy_original)
      this.debtCollectionId = null
      this.$refs['modal-debt-collection'].show()
    },
    handleOk() {
      const emitName = this.modalMode === 'create' ? 'add-debt' : 'edit-debt'
      this.$emit(emitName, {
        type: 'debt_collection',
        contract_id: this.contract.id,
        id: this.debtCollectionId,
        value: parseFloat(this.debtCollectionValue),
        debt_type: this.debtCollectionType,
        fee_to_get_original: parseFloat(this.debtCollectionFeeService),
        team_fund: parseFloat(this.debtCollectionTeamfund),
        collect_at: http.getISODate(this.collectAt),
      })
    },
    handleEditDebtCollection() {
      this.$emit('edit-debt', {
        id: this.contract.id,
        value: parseFloat(this.debtCollectionValue),
      })
    },
    handleDeleteDebtCollection() {
      this.$emit('delete-debt', this.debtCollectionId)
    },
  },
  setup(props) {
    const debtCollectionValue = ref(null)
    const debtCollectionType = ref('origin')
    const debtCollectionFeeService = ref(null)
    const debtCollectionTeamfund = ref(null)
    const collectAt = ref(null)
    const debtCollectionId = ref(null)
    const modalMode = ref('create')
    const debtCollectionDetail = ref({})
    const isShowDebtCollection = ref(false)

    watch(debtCollectionType, n => {
      debtCollectionFeeService.value = n === 'interest' ? props.contract.fee_to_buy_interest : props.contract.fee_to_buy_original
    })

    return {
      debtCollectionDetail,
      modalMode,
      debtCollectionId,
      debtCollectionValue,
      debtCollectionType,
      collectAt,
      debtCollectionFeeService,
      debtCollectionTeamfund,
      isShowDebtCollection,
      debtDetailTableColumns: [
        {
          label: 'STT',
          key: 'stt',
        },
        {
          label: 'Đội',
          key: 'doi',
          tdClass: 'text-nowrap',
        },
        {
          label: 'Tên',
          key: 'ten',
        },
        {
          label: 'Chức vụ',
          key: 'chuc-vu',
        },
        {
          label: 'Số tiền',
          key: 'so-tien',
        },
      ],
    }
  },
}
</script>

<style lang="scss">

.viewer-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(132px, 150px));
  grid-auto-rows: 1fr;
  gap: .5rem;

  .viewer-item {
    width: 100%;
    height: 100%;
    max-height: 150px;
    object-fit: cover;
    border: 2px solid white;
    box-shadow: 0 0 10px 0 rgb(34 41 47 / 20%);
    transition-duration: .3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 24px 0 rgb(34 41 47);

      .viewer-item-icon {
        background-color: rgba(115, 103, 240, .9) !important;
        color: white !important;
      }
    }

    .viewer-item-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: .5rem;
      transition-duration: .3s;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      display: flex;
      align-items: center;

      > *:not(last-child) {
        margin-right: .5rem;
      }

      img {
        object-fit: cover;
      }
    }
  }
}

.preview-file {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5% 10%;
  left: 0;
  top: 0;
  z-index: 9999;

  .icon-close {
    background-color: ghostwhite;
    box-shadow: 0 0 10px 0 rgba(black, 0.3);
    transition-duration: .3s;
    cursor: pointer;
    padding: .5rem;
    border-radius: 1rem;
    border: 2px solid white;
    position: fixed;
    top: 5%;
    right: 10%;
    transform: translate(50%, -50%);
    z-index: 9999;

    &:hover {
      box-shadow: 0 0 10px 0 black;
    }
  }
}

[page-number] {
  position: relative;

  &:before {
    content: "- Trang " attr(page-number) " -";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: "- Trang " attr(page-number) " -";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.pdf-loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9998;
  background: white;
  min-width: 30%;
  padding: 2rem 1rem;
  border-radius: 1rem;
}

.dc-top {
  margin-top: 0rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.dropdown .dropdown-toggle {
}

.debt-label {
  min-width: 130px;
  display: inline-block;
}

.sms-ticket {
  display: flex;
  align-items: center;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  background-color: #ea545512 !important;
}
</style>
