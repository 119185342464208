<template>
  <div>
    <!-- Toggler -->
    <b-link
      v-b-tooltip.hover.left="'Thêm file vào hồ sơ / hợp đồng'"
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="showSidebar"
    >
      <feather-icon
        icon="FilePlusIcon"
        size="24"
      />
    </b-link>

    <b-sidebar
      id="sidebar-add-file"
      :visible="isShowSidebar"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      class="customizer"
      shadow
      backdrop
      no-header
      right
      @hidden="isShowSidebar = !isShowSidebar"
    >
      <template>
        <div class="title-sticky-top d-flex py-1 justify-content-center text-success px-2 font-weight-bolder">
          Thay đổi tài liệu đính kèm
        </div>

        <div class="p-1 d-flex flex-grow-1 flex-column">
          <uploads
            id="uploads"
            ref="uploads"
            :key="'key-refresh-' + canRemoveFile"
            :files="files"
            :options="{autoProcessQueue:false, addRemoveLinks: canRemoveFile}"
            @file-uploaded="handleUploaded"
            @file-removed="handleRemoved"
            @all-done="handleAllDone"
          />
          <div class="flex-grow-1 d-flex align-items-end btn-start-upload">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover="'Bắt đầu tải lên và cập nhật'"
              class="suggest-action w-100"
              variant="primary"
              @click="handleUploadFiles"
            >
              <feather-icon
                icon="Uploadicon"
              />
              Bắt đầu tải và cập nhật
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BLink, VBTooltip, BSidebar, BButton,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import Uploads from '@core/components/uploads/Uploads.vue'
import Ripple from 'vue-ripple-directive'
import ability from '@/libs/acl/ability'

export default {
  components: {
    BLink,
    Uploads,
    BSidebar,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    documentFiles: {
      type: Array,
      default: () => [],
    },
    contractStatus: {
      type: String,
      default: 'entry_new',
    },
  },
  data() {
    return {
      ability,
    }
  },
  computed: {
    canRemoveFile() {
      return ability().can('remove-file', 'Contract') || this.contractStatus === 'entry_new'
    },
  },
  methods: {
    handleUploadFiles() {
      const pending = this.$refs.uploads.$refs.uploads.getQueuedFiles()
      if (pending && pending.length) {
        this.isUploading = true
        this.$refs.uploads.$refs.uploads.processQueue()
      } else {
        this.$emit('update-files', this.files)
      }
    },
    handleUploaded(file) {
      this.files.push(...file)
    },
    handleRemoved(file) {
      const foundIdx = this.files.findIndex(f => f.name === file.name)
      if (foundIdx !== -1) {
        this.files.splice(foundIdx, 1)
      }
    },
    handleAllDone() {
      if (this.isUploading) {
        this.isUploading = false
        this.$emit('update-files', this.files)
      }
    },
  },
  setup(props) {
    const isShowSidebar = ref(false)
    const isUploading = ref(false)

    const files = computed({
      get: () => [...props.documentFiles],
      set: () => {
      },
    })

    const showSidebar = () => {
      isShowSidebar.value = !isShowSidebar.value
    }

    return {
      files,
      isUploading,
      showSidebar,
      isShowSidebar,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

.nav-tabs {
  margin-bottom: .5rem !important;
}

.sidebar-lg .b-sidebar-body {
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss" scoped>
@import 'src/@core/scss/base/bootstrap-extended/include';
@import 'src/@core/scss/base/components/variables-dark';

// customizer toggle icon
.customizer-toggle {
  background: $success;
  color: $white !important;
  display: block;
  box-shadow: -3px 0px 8px rgba($black, 0.3);
  border: 1px solid aliceblue;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: fixed;
  top: calc(80% - 70px);
  width: 38px;
  height: 38px;
  right: 0;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.tab-customize {
  padding: 0 .5rem;
}

.timeline-item {
  padding-left: 1.5rem;
}

.fixed-icon-comment {
  position: absolute;
  top: -22px;

  .badge {
    padding: 0.2rem 0.4rem;
    font-size: .7rem;
    box-shadow: 0 0 4px 0;
  }

  &.note {
    left: -4px;
  }

  &.task {
    right: -2px;
  }
}

#uploads {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  overflow: hidden;
}

.btn-start-upload {
  position: sticky;
  bottom: 0;
  z-index: 99;
}

.title-sticky-top {
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: #ebebeb !important;
  color: #82868b !important;
}
</style>
