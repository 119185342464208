<template>
  <li
    v-bind="$attrs"
    class="timeline-item"
    :class="[`timeline-variant-${variant}`,
             fillBorder ? `timeline-item-fill-border-${variant}` : null,
             fullWidth ? 'w-100' : 'w-50'
    ]"
    v-on="$listeners"
  >
    <div
      v-if="!icon"
      class="timeline-item-point"
    />
    <div
      v-else
      class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
    >
      <feather-icon :icon="icon" />
    </div>
    <div
      class="timeline-content border-radius-4 py-25 px-50"
      :class="[(align || 'right') + '-side',
               align === 'right' || fullWidth ? 'bg-light-primary' : 'bg-light-success',
               fullWidth ? 'full-width' : ''
      ]"
    >
      <slot>
        <div
          class="d-flex gap-50"
          :class="align === 'right' ? 'flex-row-reverse' : 'flex-row'"
        >
          <div class="d-flex flex-column flex-grow-1 w-100">
            <div class="d-flex flex-wrap mb-25">
              <h6
                class="mb-0 w-100"
                :class="[
                  align === 'right' ? 'text-primary text-right' : 'text-left',
                  fullWidth ? 'text-primary d-flex justify-content-between align-content-center' : 'text-success'
                ]"
              >
                {{ title }}
                <br>
                <small
                  class="timeline-item-time text-nowrap text-muted"
                  v-text="time"
                />
              </h6>
            </div>
            <div class="d-flex note-wrapper">
              <pre
                class="mb-0 flex-grow-1 font-small-3 line-height-12 text-justify text-dark bg-none border-0"
                v-text="subtitle"
              />
            </div>
          </div>
          <div
            v-if="ability().can('manage', 'Settings')"
            class="note-actions d-flex flex-column gap-1"
          >
            <feather-icon
              v-if="showBtnEdit"
              v-b-tooltip.hover.left="'Chỉnh sửa'"
              icon="EditIcon"
              class="cursor-pointer"
              @click="$emit('edit', id)"
            />
            <feather-icon
              v-if="showBtnDelete"
              v-b-tooltip.hover.left="'Xóa'"
              icon="XSquareIcon"
              class="text-danger cursor-pointer"
              @click="$emit('delete', id)"
            />
          </div>
        </div>
      </slot>
    </div>
  </li>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import ability from '@/libs/acl/ability'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    showBtnEdit: {
      type: Boolean,
      default: true,
    },
    showBtnDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ability,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/bootstrap-extended/include';
// Bootstrap includes
@import 'src/@core/scss/base/components/include';
// Components includes

// Color palettes
@import 'src/@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;

          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }

        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;

  &:not(:last-of-type) {
    padding-bottom: 1rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient($theme-dark-border-color, $theme-dark-card-bg);
      }
    }

    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}

.timeline-content {

  &.full-width:before {
    left: 29px !important;
  }

  &.right-side {

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent #7367f01f #7367f01f;
      left: 15px;
      top: 10px;
      transform: rotateZ(45deg);
    }
  }

  &.left-side {
    position: relative;
    right: calc(100% + 45px);
    text-align: right;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 10px;
      border-width: 6px;
      border-style: solid;
      border-color: #28c76f1f #28c76f1f transparent transparent;
      right: -6px;
      transform: rotateZ(45deg);
    }
  }
}

.note-wrapper {
  word-break: break-word;
}
</style>
