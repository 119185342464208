var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',_vm._g(_vm._b({staticClass:"timeline-item",class:[("timeline-variant-" + _vm.variant),
           _vm.fillBorder ? ("timeline-item-fill-border-" + _vm.variant) : null,
           _vm.fullWidth ? 'w-100' : 'w-50'
  ]},'li',_vm.$attrs,false),_vm.$listeners),[(!_vm.icon)?_c('div',{staticClass:"timeline-item-point"}):_c('div',{staticClass:"timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"},[_c('feather-icon',{attrs:{"icon":_vm.icon}})],1),_c('div',{staticClass:"timeline-content border-radius-4 py-25 px-50",class:[(_vm.align || 'right') + '-side',
             _vm.align === 'right' || _vm.fullWidth ? 'bg-light-primary' : 'bg-light-success',
             _vm.fullWidth ? 'full-width' : ''
    ]},[_vm._t("default",function(){return [_c('div',{staticClass:"d-flex gap-50",class:_vm.align === 'right' ? 'flex-row-reverse' : 'flex-row'},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 w-100"},[_c('div',{staticClass:"d-flex flex-wrap mb-25"},[_c('h6',{staticClass:"mb-0 w-100",class:[
                _vm.align === 'right' ? 'text-primary text-right' : 'text-left',
                _vm.fullWidth ? 'text-primary d-flex justify-content-between align-content-center' : 'text-success'
              ]},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('br'),_c('small',{staticClass:"timeline-item-time text-nowrap text-muted",domProps:{"textContent":_vm._s(_vm.time)}})])]),_c('div',{staticClass:"d-flex note-wrapper"},[_c('pre',{staticClass:"mb-0 flex-grow-1 font-small-3 line-height-12 text-justify text-dark bg-none border-0",domProps:{"textContent":_vm._s(_vm.subtitle)}})])]),(_vm.ability().can('manage', 'Settings'))?_c('div',{staticClass:"note-actions d-flex flex-column gap-1"},[(_vm.showBtnEdit)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointer",attrs:{"icon":"EditIcon"},on:{"click":function($event){return _vm.$emit('edit', _vm.id)}}}):_vm._e(),(_vm.showBtnDelete)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"left":true}}],staticClass:"text-danger cursor-pointer",attrs:{"icon":"XSquareIcon"},on:{"click":function($event){return _vm.$emit('delete', _vm.id)}}}):_vm._e()],1):_vm._e()])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }