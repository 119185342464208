<template>
  <div>
    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header
        class="cursor-pointer user-select-none"
        :class="isShowConsultantFee ? '' : 'bg-collapsed-header'"
        @click="isShowConsultantFee = !isShowConsultantFee"
      >
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          PHÍ TƯ VẤN
        </b-badge>
        <feather-icon
          :icon="isShowConsultantFee ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="18"
          class="cursor-pointer"
        />
      </b-card-header>
      <b-card-body
        v-show="isShowConsultantFee"
      >
        <div class="debt-collection-wrapper">
          <div class="dc-top">
            <b-row />
            <b-button
              v-if="hasAbilityUpdateConsultant"
              variant="primary"
              class="text-nowrap"
              size="sm"
              @click="openModalAddConsultantFee"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
              Thêm
            </b-button>
          </div>
          <div class="dc-content">
            <b-table
              ref="refUsersListTable"
              class="position-relative pb-65"
              responsive
              small
              bordered
              primary-key="id"
              show-empty
              empty-text="Không có kết quả nào"
              :items="consultantFeeList"
              :fields="tableColumns"
            >
              <template #cell(ngay-thu)="data">
                {{ formatDate(data.item.collect_at) }}
              </template>
              <template #cell(quy-doi)="data">
                {{ formatNumber(data.item.team_fund) }} đ
              </template>
              <template #cell(thu-duoc)="data">
                {{ formatNumber(data.item.amount) }} đ
              </template>
              <template #cell(ngay-chi)="data">
                {{ formatDate(data.item.pay_out) }}
              </template>
              <template #cell(trang-thai)="data">
                {{ data.item.pay_out ? 'Đã chi' : 'Chưa chi' }}
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  class="dropdown-align-right"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    <div
                      @click="openModalEditConsultantFee(data.item)"
                    >
                      <feather-icon icon="EditIcon" />
                      Sửa
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <div
                      class="text-danger"
                      @click="e => openModalConfirmDeleteConsultantFee(data.item, e)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                      Xóa
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      id="modal-consultant-fee"
      ref="modal-consultant-fee"
      cancel-title="Hủy"
      :ok-title="modalMode === 'create' ? 'Thêm' : 'Sửa'"
      centered
      :title="(modalMode === 'create' ? 'Thêm' : 'Chỉnh sửa') + ' phí tư vấn'"
      @ok="handleOk"
    >
      <b-card-text>
        <div class="text-primary">
          {{ contract.contract_code ? ' Hợp Đồng: ' : ' Hồ Sơ: ' }}
          {{ contract.contract_code || contract.document_code }}
        </div>
        <br>
        <label for="debt-collection-value">Số tiền</label>
        <b-input-group class="input-group-merge">
          <cleave
            id="debt-collection-value"
            v-model="consultantFeeValue"
            class="form-control text-primary"
            :raw="true"
            :options="formatOptions.number"
            placeholder="Vui lòng nhập số tiền"
          />
          <b-input-group-append is-text>
            đ
          </b-input-group-append>
        </b-input-group>
        <br>
        <label for="debt-collection-fee">Ngày thu</label>
        <flat-pickr
          v-model="collectAt"
          class="form-control"
          :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
        />
        <br>
        <label for="debt-collection-fee">Ngày chi</label>
        <flat-pickr
          v-model="payOut"
          class="form-control"
          :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
        />
        <br>
        <label for="debt-collection-fee">% Đội nhận được</label>
        <b-input-group class="input-group-merge">
          <b-form-input
            id="debt-collection-fee"
            v-model="feeToGetOrigin"
            class="text-primary"
            :formatter="v => $formatPercentMinMax(v)"
            type="number"
          />
          <b-input-group-append is-text>
            %
          </b-input-group-append>
        </b-input-group>
        <br>
        <label for="debt-collection-note">Ghi chú</label>
        <b-input-group class="input-group-merge">
          <b-form-input
            id="debt-collection-note"
            v-model="note"
            class="text-primary"
            type="text"
          />
        </b-input-group>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-consultant-fee-detail"
      ref="modal-consultant-fee-detail"
      ok-only
      ok-title="Ok"
      centered
      size="lg"
      header-text-variant="primary"
      :title="'Chi tiết phí tư vấn ngày ' + formatDate(consultantFeeDetail.collect_at)"
    >
      <b-card-text>
        <br>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                sm="6"
              >
                <div>
                  <span class="debt-label">Số tiền thu được</span>
                  <span class="detail-text text-primary font-weight-bolder">{{ formatNumber(consultantFeeDetail.amount) }}</span>
                </div>
                <div>
                  <span class="debt-label">Phí DV</span>
                  <span class="detail-text text-primary font-weight-bolder">{{ parseFloat(consultantFeeDetail.fee_to_buy_original) }}%</span>
                </div>
                <div>
                  <span class="debt-label">Tổng PDV</span>
                  <span class="detail-text text-primary font-weight-bolder">{{ formatNumber(consultantFeeDetail.amount_to_buy_original) }} đ</span>
                </div>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <div>
                  <span class="debt-label">Chi đội</span>
                  <span class="detail-text text-primary font-weight-bolder">{{ formatNumber(consultantFeeDetail.amount_for_team) }} đ</span>
                </div>
                <div>
                  <span class="debt-label">Công đoàn</span>
                  <span class="detail-text text-primary font-weight-bolder">{{ formatNumber(consultantFeeDetail.syndicate_amount) }} đ</span>
                </div>
                <div>
                  <span class="debt-label">Lái xe</span>
                  <span class="detail-text text-primary font-weight-bolder">{{ formatNumber(consultantFeeDetail.driver_amount) }} đ</span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-confirm-delete-consultant-fee"
      ref="modal-confirm-delete-consultant-fee"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa Thu nợ!"
      @ok="handleDeleteConsultantFee"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> Thu nợ
        <br>
        <br>
        - Ngày: <span class="text-primary font-weight-bolder">{{ formatDate(currentDebt && currentDebt.collect_at) }}</span>
        <br>
        - Giá trị: <span class="text-primary font-weight-bolder">{{ formatNumber(currentDebt && currentDebt.amount) }}</span>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BTable,
  VBToggle,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import { subject } from '@casl/ability'
import { ref } from '@vue/composition-api'
import ability from '@/libs/acl/ability'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    Cleave,
    BCard,
    BCardHeader,
    BBadge,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormInput,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
    consultantFeeList: {
      type: Array,
      default: () => [],
    },
    consultantFeeOverview: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  computed: {
    totalAmountToBeCollected() {
      return parseFloat(this.contract.original_amount) + parseFloat(this.contract.interest_amount)
    },
    totalCustomerCollected() {
      return this.consultantFeeList.reduce((t, c) => t + c.amount * (parseFloat(c.fee_to_buy_original) / 100), 0)
    },
    totalSongLongCollected() {
      return this.consultantFeeList.reduce((t, c) => t + c.amount * ((100 - parseFloat(c.fee_to_buy_original)) / 100), 0)
    },
    hasAbilityUpdateConsultant() {
      return this.ability().can('update-consultant-fee', subject('Contract', this.contract))
    },
    currentDebt() {
      if (!this.consultantFeeId || !this.consultantFeeList.length) return {}
      return this.consultantFeeList.find(d => d.id === this.consultantFeeId) || {}
    },
  },
  methods: {
    openModalConfirmDeleteConsultantFee(row, e) {
      e.stopPropagation()
      this.consultantFeeId = row.id
      this.$refs['modal-confirm-delete-consultant-fee'].show()
    },
    openModalConsultantFeeDetail(row) {
      this.consultantFeeDetail = row
      this.$refs['modal-consultant-fee-detail'].show()
    },
    openModalEditConsultantFee(row) {
      this.modalMode = 'edit'
      this.consultantFeeValue = row.amount
      this.consultantFeeId = row.id
      this.collectAt = row.collect_at
      this.payOut = row.pay_out
      this.feeToGetOrigin = row.fee_to_buy_original
      this.note = row.note
      this.$refs['modal-consultant-fee'].show()
    },
    openModalAddConsultantFee() {
      this.modalMode = 'create'
      this.consultantFeeValue = null
      this.feeToGetOrigin = parseFloat(this.contract.fee_to_buy_original)
      this.note = ''
      this.consultantFeeId = null
      this.$refs['modal-consultant-fee'].show()
    },
    handleOk() {
      const emitName = this.modalMode === 'create' ? 'add-consultant' : 'edit-consultant'
      this.$emit(emitName, {
        type: 'consulting',
        contract_id: this.contract.id,
        id: this.consultantFeeId,
        value: parseFloat(this.consultantFeeValue),
        fee_to_get_original: parseFloat(this.feeToGetOrigin),
        note: this.note,
        collect_at: this.collectAt,
        pay_out: this.payOut,
      })
    },
    handleEditConsultantFee() {
      this.$emit('edit-consultant', {
        id: this.contract.id,
        value: parseFloat(this.consultantFeeValue),
      })
    },
    handleDeleteConsultantFee() {
      this.$emit('delete-consultant', this.consultantFeeId)
    },
  },
  setup() {
    const consultantFeeValue = ref(null)
    const consultantFeeFeeService = ref(null)
    const collectAt = ref(null)
    const payOut = ref(null)
    const feeToGetOrigin = ref(null)
    const note = ref('')
    const consultantFeeId = ref(null)
    const modalMode = ref('create')
    const consultantFeeDetail = ref({})
    const isShowConsultantFee = ref(false)

    return {
      consultantFeeDetail,
      modalMode,
      consultantFeeId,
      consultantFeeValue,
      collectAt,
      feeToGetOrigin,
      note,
      payOut,
      consultantFeeFeeService,
      isShowConsultantFee,
      tableColumns: [
        {
          label: 'Ngày thu',
          key: 'ngay-thu',
        },
        {
          label: 'Đội',
          key: 'doi',
          tdClass: 'text-primary',
          formatter: (val, key, item) => item.department_name,
        },
        {
          label: 'Thu được',
          key: 'thu-duoc',
          tdClass: 'text-primary',
        },
        {
          label: 'Đội nhận được',
          key: 'doi-nhan-duoc',
          tdClass: 'text-nowrap',
          formatter: (val, key, item) => `${item.fee_to_buy_original} %`,
        },
        {
          label: 'Quỹ đội',
          key: 'quy-doi',
          tdClass: 'text-primary',
        },
        {
          label: 'Ngày chi',
          key: 'ngay-chi',
        },
        {
          label: 'Trạng thái',
          key: 'trang-thai',
          tdClass: 'text-primary',
        },
        {
          label: 'Ghi chú',
          key: 'note',
        },
        {
          label: '',
          key: 'actions',
        },
      ],
    }
  },
}
</script>

<style lang="scss">

.viewer-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(132px, 150px));
  grid-auto-rows: 1fr;
  gap: .5rem;

  .viewer-item {
    width: 100%;
    height: 100%;
    max-height: 150px;
    object-fit: cover;
    border: 2px solid white;
    box-shadow: 0 0 10px 0 rgb(34 41 47 / 20%);
    transition-duration: .3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 24px 0 rgb(34 41 47);

      .viewer-item-icon {
        background-color: rgba(115, 103, 240, .9) !important;
        color: white !important;
      }
    }

    .viewer-item-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: .5rem;
      transition-duration: .3s;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      display: flex;
      align-items: center;

      > *:not(last-child) {
        margin-right: .5rem;
      }

      img {
        object-fit: cover;
      }
    }
  }
}

.preview-file {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5% 10%;
  left: 0;
  top: 0;
  z-index: 9999;

  .icon-close {
    background-color: ghostwhite;
    box-shadow: 0 0 10px 0 rgba(black, 0.3);
    transition-duration: .3s;
    cursor: pointer;
    padding: .5rem;
    border-radius: 1rem;
    border: 2px solid white;
    position: fixed;
    top: 5%;
    right: 10%;
    transform: translate(50%, -50%);
    z-index: 9999;

    &:hover {
      box-shadow: 0 0 10px 0 black;
    }
  }
}

[page-number] {
  position: relative;

  &:before {
    content: "- Trang " attr(page-number) " -";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: "- Trang " attr(page-number) " -";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.pdf-loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9998;
  background: white;
  min-width: 30%;
  padding: 2rem 1rem;
  border-radius: 1rem;
}

.dc-top {
  margin-top: 0rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.dropdown .dropdown-toggle {
}

.debt-label {
  min-width: 130px;
  display: inline-block;
}
</style>
