<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50 text-wrap text-left"
      >
        THÔNG TIN KHÁCH NỢ DO KHÁCH HÀNG CUNG CẤP
      </b-badge>
    </b-card-header>
    <b-card-body>
      <b-form @submit.prevent>
        <b-row>
          <!--Tình trạng hiện tại-->
          <b-col
            cols="12"
            sm="6"
          >
            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Tình trạng KN hiện tại:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.info_status }}</span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Ngành nghề hoạt động:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.info_working_area }}</span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Mối quan hệ giữa KH-KN:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.info_debt_relationship }}</span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Khách hàng đã nhờ đơn vị/cơ quan:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.info_has_contacted_with_gov }}</span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="detail-label text-break">
                Biết công ty CP ĐT mua bán nợ Song Long qua đâu?:
              </div>
              <span class="detail-text text-primary font-weight-bold">
                <template v-if="$howKnowMe.find(item => item.value === contract.info_how_to_know_me)">
                  {{ $howKnowMe.find(item => item.value === contract.info_how_to_know_me).text }}
                </template>
                {{ contract.info_how_to_know_me }}
              </span>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Ngày hẹn trả lời kết quả:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatDate(contract.reply_at) }}</span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Thời gian phát sinh:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.debt_duration }}</span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Lý do nợ:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.debt_reason }}</span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Lý do không trả:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.debt_deny_reason }}</span>
            </div>
          </b-col>
        </b-row>

        <br>

        <b-row>
          <b-col
            cols="12"
          >
            <div class="">
              <div class="detail-label font-weight-bold">
                THÔNG TIN KHÁC
              </div>
              <pre class="p-1">{{ contract.info_more }}</pre>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <div class="">
              <div class="detail-label font-weight-bold">
                Tài liệu Khách Hàng cung cấp
              </div>
              <pre class="p-1">{{ contract.info_document_attachment }}</pre>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, BCol, BForm, BRow, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BForm,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
}
</script>
