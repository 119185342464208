<template>
  <div>
    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          KẾT QUẢ ĐIỀU TRA & PHÊ DUYỆT
        </b-badge>
      </b-card-header>
      <template
        v-if="contract.status !== 'entry_new' && contract.status !== 'verify_new'"
      >
        <div class="d-flex flex-wrap flex-md-nowrap px-2 gap-2">
          <p class="d-flex flex-grow-1">
            Dự kiến thời gian thu hồi: <span class="pl-2 text-primary font-weight-bolder">
              {{ contract.verify_duration }} tháng</span>
          </p>
          <p
            v-if="contract.verified_at"
            class="d-flex flex-grow-1"
          >
            Ngày nhập kết quả điều tra ở trang:<span class="pl-2 text-primary font-weight-bolder">
              {{ formatDate(contract.verified_at) }}</span>
          </p>
        </div>
        <div class="px-2">
          <pre class="p-1 font-small-3">{{ contract.verify_info }}</pre>
        </div>
        <b-table
          ref="refUsersListTable"
          class="position-relative px-2"
          responsive
          primary-key="id"
          show-empty
          bordered
          empty-text="Không có kết quả nào"
          :items="approves"
          :fields="tableColumns"
        >
          <template #cell(nhan-vien)="data">
            <div class="text-primary">
              {{ data.item.user_fullname }}
            </div>
          </template>

          <template #cell(di-xac-minh)="data">
            <b-badge
              v-if="data.item.verification"
              variant="success"
            >
              Đi xác minh
            </b-badge>
          </template>

          <template #cell(phong-ban)="data">
            <small
              v-if="data.item.user_job_title_code"
            >{{ $userJobTitleOptions.find(u => u.value === data.item.user_job_title_code).label }} - </small>
            <span class="text-primary">{{ data.item.department_name || 'Đội' }}</span>
          </template>

          <template #cell(phe-duyet)="data">
            <b-badge
              :class="'bg-'+data.item.status"
            >
              {{ $contractApproving[data.item.status].label }}
            </b-badge>
          </template>
        </b-table>
      </template>
      <div
        v-else
        class="px-2 pb-2 w-100"
      >
        Chưa có kết quả điều tra
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, BTable, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BBadge,
    BTable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    approves() {
      const ujt = this.$userJobTitleOptions
      const arr = this.contract.approves.map(a => Object.assign(a, {
        zIndex: ujt.find(u => u.value === a.user_job_title_code).zIndex,
      }))
      arr.sort((a, b) => b.zIndex - a.zIndex)
      return arr || []
    },
  },
  setup() {
    const tableColumns = [
      {
        label: 'Nhân viên',
        key: 'nhan-vien',
      },
      {
        label: 'Đi xác minh',
        key: 'di-xac-minh',
      },
      {
        label: 'Phòng ban',
        key: 'phong-ban',
      },
      {
        label: 'Phê duyệt',
        key: 'phe-duyet',
      },
    ]

    return {
      tableColumns,
    }
  },
}
</script>
