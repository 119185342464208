import {
  computed, reactive, ref, watch,
} from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'

const ICON_PDF = require('@/assets/images/icons/icon-pdf.png')
const ICON_DOC = require('@/assets/images/icons/icon-doc.png')

export default function useContractsCreate() {
  const today = new Date()
  const listActivity = ref([])
  const howKnowMe = [
    {
      value: 'google',
      text: 'Google',
    },
    {
      value: 'khach-cu',
      text: 'Khách hàng cũ',
    },
    {
      value: 'gioi-thieu',
      text: 'Giới thiệu',
    },
    {
      value: 'banner',
      text: 'Banner',
    },
    {
      value: 'khac',
      text: 'Khác',
    },
  ]

  const routeParamsId = ref('')
  const contract = reactive({
    customer: reactive({
      category: ref(''),
    }),
    debtor: reactive({
      category: ref(''),
      address: ref(''),
      street: ref(''),
      ward: ref(''),
      district: ref(''),
      province: ref(''),
    }),
    reply_at: ref(today),
    amount_for_team_percent: ref(null),
    original_amount: ref(0),
    interest_amount: ref(0),
    fee_to_buy_original: ref(0),
    fee_to_buy_interest: ref(0),
    debt_description: ref(''),
    debt_reason: ref(''),
    debt_deny_reason: ref(''),
    debt_duration: ref(''),
    debt_from_year: ref(''),
    debt_from_month: ref(''),
    is_has_plan: ref(false),
    status: ref(null),
    info_status: ref(''),
    info_working_area: ref(''),
    info_debt_relationship: ref(''),
    info_more: ref(''),
    info_document_attachment: ref(''),
    info_has_contacted_with_gov: ref(''),
    ratio_of_pay_consultant: ref(20),
    ratio_of_refund: ref(0),
    amount_of_refund: ref(0),
    amount_of_break_contract: ref(0),
    fee_to_break_contract: ref(0),
    document_files: ref([]),
    contract_code: ref(''),
    document_code: ref(''),
    department_id: ref(''),
    appendix_code: ref(''),
    department: ref({}),
    approves: ref([]),
    authenticators: ref([]),
    verify_duration: ref(null),
    verify_suggestion_status: ref(null),
    liquidity_result: ref(''),
    expired_at: ref(null),
    liquidity_start_date: ref(null),
    sign_at: ref(null),
    verify_estimate_time: ref(null),
    liquidity_finish_date: ref(null),
    verify_info: ref(''),
    is_calculate_team_fund: ref(false),
    fee_for_consultant: computed({
      get: () => Math.floor(parseFloat(contract.fee_for_consultant_input)
        + parseFloat(contract.fee_for_consultant_contract)
        + parseFloat(contract.fee_for_consultant_service)),
      set: () => {},
    }),
    fee_for_consultant_input: ref(0),
    fee_for_consultant_contract: ref(0),
    fee_for_consultant_service: ref(0),
    amount_to_buy_interest: computed({
      get: () => Math.floor((contract.interest_amount * contract.fee_to_buy_interest) / 100),
      set: () => {},
    }),
    amount_to_buy_original: computed({
      get: () => Math.floor((contract.original_amount * contract.fee_to_buy_original) / 100),
      set: () => {},
    }),
    info_how_to_know_me_type: ref({}),
    info_how_to_know_me: ref(''),
  })

  watch(() => contract.info_how_to_know_me_type, n => {
    if (n.value !== 'khac') {
      contract.info_how_to_know_me = contract.info_how_to_know_me_type.text
    }
  })

  watch(() => contract.info_how_to_know_me, n => {
    const found = howKnowMe.find(item => item.text === n)
    if (found) {
      contract.info_how_to_know_me_type = found
    } else {
      contract.info_how_to_know_me_type = howKnowMe.find(item => item.value === 'khac')
    }
  })

  const users = ref([])

  const setComputed = () => {
    // contract.debtor.address = computed({
    //   get: () => `${contract.debtor.street || ''}, ${contract.debtor.ward || ''}, ${contract.debtor.district || ''}, ${contract.debtor.province || ''}`,
    //   set: () => {},
    // })
    contract.fee_for_consultant = computed({
      get: () => Math.floor(parseFloat(contract.fee_for_consultant_input)
        + parseFloat(contract.fee_for_consultant_contract)
        + parseFloat(contract.fee_for_consultant_service)),
      set: () => {},
    })
    contract.amount_to_buy_interest = computed({
      get: () => Math.floor((contract.interest_amount * contract.fee_to_buy_interest) / 100),
      set: () => {},
    })
    contract.amount_to_buy_original = computed({
      get: () => Math.floor((contract.original_amount * contract.fee_to_buy_original) / 100),
      set: () => {},
    })
  }
  setComputed()

  watch([() => contract.debtor.street, () => contract.debtor.ward, () => contract.debtor.district, () => contract.debtor.province], () => {
    contract.debtor.address = [
      contract.debtor.street,
      contract.debtor.ward,
      contract.debtor.district,
      contract.debtor.province,
    ].filter(item => item).join(', ')
  })

  const fakeContractData = () => {
    contract.customer.name = 'Nguyễn Tiến Doanh'
    contract.customer.address = 'Khánh Hòa'
    contract.customer.ward = 'Diên Xuân'
    contract.customer.district = 'Diên Khánh'
    contract.customer.province = 'Khánh Hòa'
    contract.customer.category = 'personal'
    contract.customer.phone = '0384989499'
    contract.customer.email = 'ntd@gmail.com'
    contract.customer.tax_code = '3213213'
    contract.customer.more = 'Ngạo thế cửu trọng thiên'
    contract.customer.fax_code = '6166'
    contract.customer.rep_gender = 'Ông'
    contract.customer.rep_name = 'Thái Doãn Doãn'
    contract.customer.rep_job_title = 'dev'
    contract.customer.rep_card_id = '225555255'
    contract.customer.rep_card_date = today
    contract.customer.rep_card_place = 'Khánh Hòa'
    contract.customer.contact_name = 'Nguyễn Văn Hệ'
    contract.customer.contact_phone = '0323232363'
    contract.customer.contact_email = 'doanh@gmail.com'
    contract.debtor.name = 'Lê Hoàng Công Công'
    contract.debtor.address = 'HCM'
    contract.debtor.street = '801/10 Lạc Long Quân'
    contract.debtor.ward = 'Phường 5'
    contract.debtor.district = 'Quân 10'
    contract.debtor.province = 'Hồ Chí Minh'
    contract.debtor.category = 'personal'
    contract.debtor.phone = '0398984932'
    contract.debtor.email = 'congcong@gmail.com'
    contract.debtor.tax_code = '3908124'
    contract.debtor.more = 'Đấu la đại lục'
    contract.debtor.rep_gender = 'Ông'
    contract.debtor.rep_name = 'Thái Công Danh'
    contract.debtor.rep_job_title = 'phụ hồ'
    contract.debtor.rep_card_id = '302321329'
    contract.debtor.rep_card_date = today
    contract.debtor.rep_card_place = 'Đồng Nai'
    contract.debtor.contact_name = 'Lê Công Hoàng'
    contract.debtor.contact_phone = '0321923954'
    contract.debtor.contact_email = 'hoang@gmail.com'
    contract.original_amount = 10000000
    contract.interest_amount = 2000000
    contract.fee_to_buy_original = 30
    contract.fee_to_buy_interest = 70
    contract.debt_description = 'Nợ dai'
    contract.debt_reason = 'cưới vợ'
    contract.debt_deny_reason = 'Cưới vợ âm tiền'
    contract.debt_duration = '1 năm'
    contract.debt_from_year = '2023'
    contract.debt_from_month = '12'
    contract.info_status = 'đang đi du lịch'
    contract.info_working_area = 'Việt Nam'
    contract.info_debt_relationship = 'Bạn bè'
    contract.info_more = 'thông tin khác'
    contract.info_document_attachment = 'thông tin khách hàng cung cấp'
    contract.info_has_contacted_with_gov = 'Chưa'
    contract.info_how_to_know_me = 'website'
    contract.fee_for_consultant_input = 100000
    contract.fee_for_consultant_contract = 100000
    contract.fee_for_consultant_service = 100000
    contract.ratio_of_pay_consultant = 20
    contract.ratio_of_refund = 40
    contract.amount_of_refund = 13000000
    contract.amount_of_break_contract = 30
    contract.fee_to_break_contract = 30
    contract.document_files = []
    contract.is_calculate_team_fund = 1
    setComputed()
  }

  const contractLiquiditySignedDate = ref('')
  const contractLiquidityResult = ref('')
  const contractSignedDate = ref('')
  const contractSignedDateReal = ref('')
  const contractSignedExpiredAt = ref('')
  const contractSignedContractCode = ref('')
  const appendixCode = ref('')

  const debtCollectionOverview = ref({})
  const debtCollectionList = ref([])
  const debtCollectionValue = ref(null)
  const consultantFeeList = ref([])
  const consultantFeeOverview = ref({})
  const handOverAt = ref(null)

  const templates = ref([])
  const downloadTemplateData = ref({})

  const departmentList = ref([])
  const departmentIdAssigned = ref('')
  const creatorIdAssigned = ref('')
  const verifyContractData = ref({})
  const contractMembers = ref([])
  const selectedTemplateToDownload = ref([])

  const usersPKH = computed(() => users.value.filter(item => item.department.code.toLowerCase() === 'pkh'))

  const canShowThisView = computed({
    get: () => ability().can('read', subject('Contract', contract))
      || ability().can('read-open-contract', subject('Contract', contract)),
    set: () => {},
  })
  const getFileIcon = file => {
    const type = file.filename.split('.').pop()
    if (type.toLowerCase() === 'pdf') return ICON_PDF
    if (type.toLowerCase() === 'doc' || type.toLowerCase() === 'docx') return ICON_DOC
    return file.large || file.origin
  }

  const departmentTeam = computed({
    get: () => {
      let list = departmentList.value.filter(d => d.code.toLowerCase().startsWith('d'))
      list = list.sort((a, b) => (a.code < b.code ? -1 : 1))
      return list
    },
    set: () => {},
  })

  const deleteContract = (id, cb) => http.api(store.dispatch('contract/deleteContract', { id }), cb)

  const createContract = (data, cb) => http.api(store.dispatch('contract/createContract', http.fcContract(data)), cb)

  const updateContract = (data, cb) => http.api(store.dispatch('contract/updateContract', http.fcContract(data)), cb)

  const sendDebSMS = (data, cb) => http.api(store.dispatch('contract/sendDebSMS', data), cb)

  const handleOver = (data, cb) => http.api(store.dispatch('contract/handleOver', data), cb)

  const updateDebtCollectionOverview = () => {
    let totalDebtAmount = 0
    let totalDebtAmountOrigin = 0
    let totalDebtAmountInterest = 0
    const totalAmount = parseFloat(contract.original_amount) + parseFloat(contract.interest_amount)

    debtCollectionList.value.forEach(d => {
      const amount = Number.parseInt(d.amount, 10) || 0
      totalDebtAmount += amount
      if (d.debt_type === 'origin') {
        totalDebtAmountOrigin += amount
      } else {
        totalDebtAmountInterest += amount
      }
    })

    debtCollectionOverview.value = {
      totalDebtAmount,
      totalDebtAmountOrigin,
      totalDebtAmountInterest,
      totalAmount,
      percent: ((100 * totalDebtAmount) / totalAmount).toFixed(2),
    }
  }

  const fetchActivities = filter => {
    const queryString = http.buildQuery(filter || {
      contract_id: filter.id || contract.id,
    }) || ''
    return http.api(store.dispatch('contract/fetchActivities', queryString), res => {
      listActivity.value = res.data || []
    })
  }

  const fetchContract = (id, cb) => http.api(store.dispatch('contract/fetchContract', { id }), res => {
    const documentFiles = Array.isArray(res.data.document_files) ? res.data.document_files : []
    Object.assign(contract, res.data, {
      document_files: documentFiles.map(f => ({
        ...f,
        name: f.name || f.filename,
        large: f.large || f.origin,
        thumbnail: getFileIcon(f),
      })),
    })
    downloadTemplateData.value.contract_id = res.data.id
    setComputed()
    // update for modal Báo cáo xác minh
    if (contract.verify_info) {
      verifyContractData.value.verify_info = contract.verify_info
      verifyContractData.value.verify_duration = contract.verify_duration
      verifyContractData.value.verify_suggestion_status = contract.verify_suggestion_status
    }

    if (contract.approves && contract.authenticators && contract.approves.length) {
      contract.approves = contract.approves.map(approver => {
        const appr = approver
        contract.authenticators.forEach(auth => {
          if (appr.user_fullname === auth.fullname) {
            appr.verification = auth
          }
        })
        return appr
      })
    }

    contractSignedContractCode.value = res.data.contract_code || ''
    appendixCode.value = res.data.appendix_code || ''

    updateDebtCollectionOverview()

    if (cb) cb(res)
    return res
  })

  const fetchUsers = filter => {
    const queryString = http.buildQuery(filter || {
      page: 0,
      perpage: 999,
    }) || ''
    return http.api(store.dispatch('contract/fetchUsers', queryString), res => {
      users.value = res.data || []
    })
  }
  const fetchDebtCollection = (id, cb) => http.api(store.dispatch('contract/getDebtCollection', { id: id || routeParamsId.value, type: 'debt_collection' }), cb).then(res => {
    debtCollectionList.value = res.data || []
    updateDebtCollectionOverview()
  })
  const addDebtCollection = (data, cb) => http.api(store.dispatch('contract/addDebtCollection', data), cb).then(res => {
    fetchDebtCollection()
    updateDebtCollectionOverview()
  })
  const editDebtCollection = (data, cb) => http.api(store.dispatch('contract/editDebtCollection', data), cb).then(res => {
    fetchDebtCollection()
    Object.assign(debtCollectionList.value.find(d => d.id === res.data.id), res.data)
  })
  const deleteDebtCollection = (id, cb) => http.api(store.dispatch('contract/deleteDebtCollection', { id }), cb).then(res => {
    fetchDebtCollection()
  })
  const revokeTeamContract = (id, cb) => http.api(store.dispatch('contract/revokeTeamContract', { id }), cb).then(res => {
  })
  //
  const fetchConsultantFee = (id, cb) => http.api(store.dispatch('contract/getDebtCollection', { id: id || routeParamsId.value, type: 'consulting' }), cb).then(res => {
    consultantFeeList.value = res.data || []
    updateDebtCollectionOverview()
  })
  const addConsultantFee = (data, cb) => http.api(store.dispatch('contract/addConsulting', data), cb).then(res => {
    fetchConsultantFee()
  })
  const editConsultantFee = (data, cb) => http.api(store.dispatch('contract/editConsulting', data), cb).then(res => {
    fetchConsultantFee()
    Object.assign(consultantFeeList.value.find(d => d.id === res.data.id), res.data)
  })
  const deleteConsultantFee = (id, cb) => http.api(store.dispatch('contract/deleteConsulting', { id }), cb).then(res => {
    fetchConsultantFee()
  })

  const assignTeam = (data, cb) => http.api(store.dispatch('contract/assignTeam', data), cb)
  const changeCreator = (data, cb) => http.api(store.dispatch('contract/changeCreator', data), cb)

  const verificationReport = (data, cb) => http.api(store.dispatch('contract/verificationReport', data), cb)

  const updateContractStatus = (data, cb) => http.api(store.dispatch('contract/updateStatus', data), res => {
    if (cb) cb(res)
    return res
  })

  return {
    debtCollectionOverview,
    consultantFeeOverview,
    consultantFeeList,
    debtCollectionList,
    debtCollectionValue,
    templates,
    downloadTemplateData,
    routeParamsId,
    contractLiquidityResult,
    contractLiquiditySignedDate,
    canShowThisView,
    departmentTeam,
    departmentList,
    verifyContractData,
    users,
    listActivity,
    usersPKH,
    departmentIdAssigned,
    creatorIdAssigned,
    contractMembers,
    contract,
    contractSignedDate,
    contractSignedDateReal,
    handOverAt,
    contractSignedExpiredAt,
    appendixCode,
    contractSignedContractCode,
    selectedTemplateToDownload,
    revokeTeamContract,
    fetchConsultantFee,
    fetchUsers,
    addConsultantFee,
    editConsultantFee,
    deleteConsultantFee,
    fetchDebtCollection,
    editDebtCollection,
    deleteDebtCollection,
    addDebtCollection,
    fakeContractData,
    fetchContract,
    createContract,
    deleteContract,
    handleOver,
    updateContract,
    assignTeam,
    changeCreator,
    sendDebSMS,
    verificationReport,
    updateContractStatus,
    fetchActivities,
  }
}
