import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchComments: (ctx, q) => axiosIns.get(`/api.comment${q || ''}`),
    fetchCommentByContract: (ctx, { id }) => axiosIns.get(`/api.comment/contract/${id}`),
    fetchComment: (ctx, { id }) => axiosIns.get(`/api.comment/${id}`),
    createComment: (ctx, data) => axiosIns.post('/api.comment', data),
    updateComment: (ctx, data) => axiosIns.put(`/api.comment/${data.id}`, data),
    deleteComment: (ctx, { id }) => axiosIns.delete(`/api.comment/${id}`),
  },
}
