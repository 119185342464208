<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        NỘI DUNG MUA BÁN NỢ
      </b-badge>
    </b-card-header>
    <b-card-body>
      <b-form @submit.prevent>
        <b-row>
          <!-- số tiền nợ gốc -->
          <b-col
            cols="12"
            sm="6"
            lg="12"
            class="pt-50 bg-primary bg-lighten-5 pt-50 mb-75 border-radius-4 border-light border-primary"
          >
            <div class="d-flex justify-content-between">
              <div class="mb-75 font-weight-bold">
                SỐ TIỀN NỢ GỐC
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.original_amount) }} <small>đ</small></span>
            </div>
            <div class="d-flex justify-content-between">
              <div class="mb-75">
                Mức giá mua nợ gốc
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.fee_to_buy_original }}  <small>%</small></span>
            </div>
            <div class="d-flex justify-content-between">
              <div class="mb-75 flex-grow-1">
                <feather-icon
                  icon="CornerDownRightIcon"
                  size="10"
                  style="margin-top: -6px"
                />
                Mức giá Song Long nhận
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ parseFloat((100-contract.fee_to_buy_original).toFixed(3)) }}  <small>%</small></span>
            </div>
            <div class="d-flex justify-content-between">
              <div class="mb-75">
                <feather-icon
                  icon="CornerDownRightIcon"
                  size="10"
                  style="margin-top: -6px"
                />
                Giá trị mua nợ gốc
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.amount_to_buy_original) }} <small>đ</small></span>
            </div>
            <div class="d-flex justify-content-between">
              <div class="mb-75 flex-grow-1">
                <feather-icon
                  icon="CornerDownRightIcon"
                  size="10"
                  style="margin-top: -6px"
                />
                Giá trị Song Long nhận
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.original_amount - contract.amount_to_buy_original) }} <small>đ</small></span>
            </div>
          </b-col>

          <!--số tiền nợ lãi-->
          <b-col
            cols="12"
            sm="6"
            lg="12"
            class="pt-50 bg-primary bg-lighten-5 pt-50 mb-75 border-radius-4 border-light border-primary"
          >
            <div class="d-flex justify-content-between">
              <div class="mb-75 font-weight-bold">
                SỐ TIỀN NỢ LÃI
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.interest_amount) }} <small>đ</small></span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="mb-75">
                Mức giá mua nợ lãi
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.fee_to_buy_interest) }}  <small>%</small></span>
            </div>
            <div class="d-flex justify-content-between">
              <div class="mb-75 flex-grow-1">
                <feather-icon
                  icon="CornerDownRightIcon"
                  size="10"
                  style="margin-top: -6px"
                />
                Mức giá Song Long nhận
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ parseFloat((100-contract.fee_to_buy_interest).toFixed(3)) }}  <small>%</small></span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="mb-75 flex-grow-1">
                <feather-icon
                  icon="CornerDownRightIcon"
                  size="10"
                  style="margin-top: -6px"
                />
                Giá trị mua nợ lãi
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.amount_to_buy_interest) }} <small>đ</small></span>
            </div>
            <div class="d-flex justify-content-between">
              <div class="mb-75 flex-grow-1">
                <feather-icon
                  icon="CornerDownRightIcon"
                  size="10"
                  style="margin-top: -6px"
                />
                Giá trị Song Long nhận
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.interest_amount - contract.amount_to_buy_interest) }} <small>đ</small></span>
            </div>

          </b-col>

          <!--phí tư vấn-->
          <b-col
            cols="12"
            sm="6"
            lg="12"
            class="pt-50"
          >
            <div class="d-flex justify-content-between">
              <div class="mb-75 font-weight-bold">
                TỔNG PHÍ TƯ VẤN
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.fee_for_consultant) }} <small>đ</small></span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="mb-75">
                - Đợt 1: Sau khi nhận hồ sơ
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.fee_for_consultant_input) }} <small>đ</small></span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="mb-75">
                - Đợt 2: Sau khi ký hợp đồng
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.fee_for_consultant_contract) }} <small>đ</small></span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="mb-75">
                - Đợt 3: Sau khi thu nợ đạt <span class="text-primary font-weight-bolder">{{ contract.ratio_of_pay_consultant }}%</span> giá trị nợ
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ formatNumber(contract.fee_for_consultant_service) }} <small>đ</small></span>
            </div>

            <div class="d-flex justify-content-between">
              <div class="detail-label">
                Ghi chú:
              </div>
              <span class="detail-text text-primary font-weight-bold">{{ contract.debt_description }}</span>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCol, BForm, BRow, VBToggle, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BBadge,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
}
</script>
