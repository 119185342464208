<template>
  <div>
    <!-- Toggler -->
    <b-link
      v-b-tooltip.hover.left="'Ghi chú và tác vụ'"
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="showSidebar"
    >
      <feather-icon
        icon="TwitchIcon"
        size="16"
      />
      <div
        v-if="comment.filter(c => c.type === 'note')"
        class="fixed-icon-comment note"
      >
        <b-badge
          pill
          :variant="latestComment.type === 'note' ? 'success': 'light-success'"
          :class="latestComment.type === 'note' ? '': 'bg-light'"
        >
          {{ comment.filter(c => c.type === 'note').length }}
        </b-badge>
      </div>
      <div
        v-if="comment.filter(c => c.type === 'task')"
        class="fixed-icon-comment task"
      >
        <b-badge
          pill
          :variant="latestComment.type === 'task' ? 'primary': 'light-primary'"
          :class="latestComment.type === 'task' ? '': 'bg-light'"
        >
          {{ comment.filter(c => c.type === 'task').length }}
        </b-badge>
      </div>
    </b-link>

    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isShowComment"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      class="customizer"
      shadow
      backdrop
      no-header
      right
      @hidden="isShowComment = !isShowComment"
    >
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2">
          <div class="flex-grow-1 text-primary font-weight-bolder font-medium-1 d-flex justify-content-between py-1">
            <div class="text-success">
              Phòng Khách Hàng
            </div>
            <div>Đội Pháp Chế</div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="tab-customize w-50">
            <div
              v-if="ability().can('create', 'note')"
              class="d-flex mb-2 mt-1 pr-1 pl-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal="'modal-comment'"
                variant="success"
                size="sm"
                @click="handleCreateComment('note')"
              >
                <feather-icon
                  icon="PlusIcon"
                />
              </b-button>
            </div>
          </div>
          <div class="tab-customize w-50">
            <div
              v-if="ability().can('create', 'task')"
              class="d-flex mb-2 mt-1 pr-1 pl-1 justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal="'modal-comment'"
                variant="primary"
                size="sm"
                @click="handleCreateComment('task')"
              >
                <feather-icon
                  icon="PlusIcon"
                />
              </b-button>
            </div>
          </div>
        </div>
        <div>
          <app-timeline class="d-flex flex-column align-items-end">
            <app-timeline-item-custom
              v-for="(c, idx) in comment"
              :id="c.id.toString()"
              :key="idx"
              :data="c"
              :title="c.full_name"
              :subtitle="c.content"
              :align="c.type === 'note' ? 'left' : 'right'"
              :icon="c.type === 'note' ? 'Edit3Icon' : 'UserIcon'"
              :time="formatDate(c.created_at, true)"
              :variant="c.type === 'note' ? 'success' : 'primary'"
              @edit="handleEditComment"
              @delete="handleDeleteComment"
            />
          </app-timeline>
        </div>
        <div
          v-if="!comment.length"
          class="text-center"
        >
          Chưa có ghi chú và tác vụ nào!
        </div>
      </template>
    </b-sidebar>

    <b-modal
      id="modal-comment"
      ref="modal-comment"
      cancel-title="Hủy"
      :ok-title="modalCommentType === 'create' ? 'Tạo' : 'Chỉnh sửa'"
      header-text-variant="primary"
      :title="(modalCommentType === 'create' ? 'Tạo ' : 'Chỉnh sửa ') + (currentCommentType === 'note' ? 'ghi chú' : 'tác vụ')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="createComment"
    >
      <b-form-textarea
        id="comment-content"
        v-model="newContent"
        :class="errorText? 'border-danger' : ''"
        type="text"
        :placeholder="'Nội dung ' + (currentCommentType === 'note' ? 'ghi chú' : 'tác vụ')"
        rows="3"
      />
      <span
        v-if="errorText"
        class="text-danger"
      >{{ errorText }}</span>
    </b-modal>

    <b-modal
      id="modal-confirm-delete-comment"
      ref="modal-confirm-delete-comment"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      :title="'Xác nhận xóa ' + (currentComment.type === 'note' ? 'ghi chú' : 'tác vụ')"
      @ok="$emit('delete-comment', currentCommentId)"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span>
        {{ currentComment.type === 'note' ? 'ghi chú' : ' tác vụ: ' }}
        <br>
        <br>
        <pre
          class="border-danger p-50"
          v-text="currentComment.content"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge, BButton, BFormTextarea, BLink, BModal, BSidebar, VBTooltip, BCardText,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItemCustom from '@core/components/app-timeline/AppTimelineItemCustom.vue'
import Ripple from 'vue-ripple-directive'
import { computed, ref } from '@vue/composition-api'
import ability from '@/libs/acl/ability'

export default {
  components: {
    // BSV
    BLink,
    BFormTextarea,
    BModal,
    AppTimeline,
    AppTimelineItemCustom,
    BButton,
    BSidebar,
    BBadge,
    BCardText,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    comment: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ability,
    }
  },
  methods: {
    handleCreateComment(type) {
      this.modalCommentType = 'create'
      this.currentCommentType = type
      this.$refs['modal-comment'].show()
    },
    handleEditComment(id) {
      this.modalCommentType = 'edit'
      this.currentCommentId = id
      this.$refs['modal-comment'].show()
      this.newContent = this.comment.find(item => item.id.toString() === id)?.content || ''
    },
    handleDeleteComment(id) {
      this.currentCommentId = id
      this.$refs['modal-confirm-delete-comment'].show()
    },
  },
  setup(props, { emit }) {
    const isShowComment = ref(false)

    const currentCommentType = ref('note')
    const modalCommentType = ref('create')
    const currentCommentId = ref('')
    const currentComment = computed(() => {
      const found = props.comment.find(item => item.id.toString() === currentCommentId.value)
      return found || ''
    })

    const newContent = ref('')

    const errorText = ref('')

    const resetModal = () => {
      newContent.value = ''
      errorText.value = ''
    }

    const showSidebar = () => {
      isShowComment.value = !isShowComment.value
      // emit('click')
    }

    const latestComment = computed(() => props.comment[0] || {})

    const createComment = bvModalEvt => {
      switch (newContent.value) {
        case '':
          errorText.value = 'Không được để trống'
          bvModalEvt.preventDefault()
          return
        default:
          errorText.value = ''
          emit(modalCommentType.value === 'create' ? 'create-comment' : 'edit-comment', {
            id: currentCommentId.value,
            content: newContent.value,
            type: currentCommentType.value,
          })
      }
    }

    return {
      latestComment,
      currentComment,
      currentCommentId,
      modalCommentType,
      currentCommentType,
      newContent,
      showSidebar,
      errorText,
      createComment,
      isShowComment,
      resetModal,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

.nav-tabs {
  margin-bottom: .5rem !important;
}
</style>

<style lang="scss" scoped>
@import 'src/@core/scss/base/bootstrap-extended/include';
@import 'src/@core/scss/base/components/variables-dark';

// customizer toggle icon
.customizer-toggle {
  background: $primary;
  color: $white !important;
  display: block;
  box-shadow: -3px 0px 8px rgba($black, 0.3);
  border: 1px solid aliceblue;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: fixed;
  top: 80%;
  width: 38px;
  height: 38px;
  right: 0;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 99;
}

.tab-customize {
  padding: 0 .5rem;
}

.timeline-item {
  padding-left: 1.5rem;
}

.fixed-icon-comment {
  position: absolute;
  top: -22px;

  .badge {
    padding: 0.2rem 0.4rem;
    font-size: .7rem;
    box-shadow: 0 0 4px 0;
  }

  &.note {
    left: -4px;
  }

  &.task {
    right: -2px;
  }
}
</style>
