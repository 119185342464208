<template>
  <div class="text-center">
    Không đủ quyền truy cập trang này!
    <br>
    <br>
    <b-button
      variant="primary"
      @click="gohome()"
    >
      Quay lại
    </b-button>
  </div>
</template>

<script>
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'NotPermission',
  components: { BButton },
  methods: {
    gohome() {
      this.$goback(getHomeRouteForLoggedInUser(this.$userData.role))
    },
  },
}
</script>

<style scoped>

</style>
