<template>
  <b-card
    no-body
    class="box-shadow-1"
    :class="'bg-lighten-' + contract.status"
  >
    <b-card-body>
      <small
        v-if="contract.old_creator && contract.old_creator.length"
        class="old-creator"
      >
        <span> NV PKH cũ: {{ contract.old_creator[contract.old_creator.length - 1].department_name }}</span>
      </small>
      <b-form @submit.prevent>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-value">
                <b-badge :class="'bg-' + contract.status">
                  {{ $status.getName(contract.status) }}
                </b-badge>
                <span
                  v-if="contract.is_hand_over !== undefined && contract.is_hand_over !== null"
                  v-b-tooltip.hover.html="contract.is_hand_over ? ('Đã giao đội: ' + formatDate(contract.hand_over_at)) : 'Chưa giao đội'"
                  :class="'text-' + contract.status"
                >
                  <feather-icon
                    size="16"
                    class="font-weight-bold ml-75 mr-25"
                    stroke-width="3"
                    :icon="contract.is_hand_over ? 'UserCheckIcon' : 'AlertTriangleIcon'"
                  />
                </span>
              </div>
            </div>

            <div
              class="d-flex flex-wrap justify-content-between justify-content-sm-start font-small-3 mt-50"
            >
              <div class="row-value">
                <b-form-checkbox
                  id="checkbox-has-plan"
                  v-model="isHasPlan"
                  v-b-tooltip:hover="isHasPlan ? '' : 'Nhấn để thay đổi'"
                  name="checkbox-has-plan"
                  variant="success"
                  class="user-select-none"
                  :class="'custom-control-' + (isHasPlan ? 'dark' : 'primary')"
                  :disabled="!canChangeHasPlan"
                  @change="handleChangeIsHasPlan"
                >
                  <span :class="'text-' + (isHasPlan ? 'dark' : 'primary')">
                    {{ isHasPlan ? 'Có kế hoạch' : 'Chưa có kế hoạch' }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                Mã số HS
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">
                  {{ contract.document_code }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div
                class="row-label"
                :class="contract.contract_code? 'text-black-soft' : ''"
              >
                Mã số HĐ
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">{{ contract.contract_code }}</span>
              </div>
            </div>
            <div
              v-if="contract.appendix_code"
              class="d-flex flex-wrap justify-content-between justify-content-sm-start"
            >
              <div
                class="row-label"
                :class="contract.contract_code? 'text-black-soft' : ''"
              >
                Mã số PLHĐ
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">{{ contract.appendix_code }}</span>
              </div>
            </div>
            <div
              v-if="contract.liquidity_code"
              class="d-flex flex-wrap justify-content-between justify-content-sm-start"
            >
              <div
                class="row-label"
                :class="contract.liquidity_code? 'text-black-soft' : ''"
              >
                Mã số TLHĐ
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">{{ contract.liquidity_code }}</span>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                NV PKH
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">{{ contract.creator && contract.creator.fullname }}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                Ngày lập
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">
                  {{ formatDate(contract.created_at) }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                Ngày ký HĐ
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">
                  {{ formatDate(contract.sign_at) }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div class="row-label text-black-soft">
                Ngày ký TT
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">
                  {{ formatDate(contract.real_sign_at) }}
                </span>
              </div>
            </div>
            <div
              v-if="contract.liquidity_finish_date"
              class="d-flex flex-wrap justify-content-between justify-content-sm-start text-danger"
            >
              <div class="row-label">
                Ngày thanh lý
              </div>
              <div class="row-value">
                {{ formatDate(contract.liquidity_finish_date) }}
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div
                class="row-label"
                :class="(contract.department && contract.department.name)? 'text-black-soft' : ''"
              >
                Đội
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">
                  {{ contract.department && contract.department.name }}
                </span>
                <small
                  v-if="contract.old_team && contract.old_team.length"
                  :class="'text-'+ contract.status"
                >({{ contract.old_team[contract.old_team.length - 1].department_name }}
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="14"
                    class="mb-25"
                  />
                  {{ contract.department && contract.department.name }})
                </small>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div
                class="row-label"
                :class="contract.created_at? 'text-black-soft' : ''"
              >
                Ngày trả lời
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">
                  {{ formatDate(contract.reply_at) }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div
                class="row-label"
                :class="contract.created_at? 'text-black-soft' : ''"
              >
                Hết hạn HĐ
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">
                  {{ formatDate(contract.expired_at) }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between justify-content-sm-start">
              <div
                class="row-label"
                :class="contract.liquidity_start_date? 'text-black-soft' : ''"
              >
                Ngày chờ TL
              </div>
              <div class="row-value">
                <span :class="'text-'+ contract.status">
                  {{ formatDate(contract.liquidity_start_date) }}
                </span>
              </div>
            </div>
            <div
              v-if="contract.liquidity_finish_date"
              class="d-flex flex-wrap justify-content-between justify-content-sm-start text-danger"
            >
              <div class="row-label">
                Kết quả TL
              </div>
              <div class="row-value">
                {{ contract.liquidity_result }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCol, BForm, BRow, VBToggle, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ability from '@/libs/acl/ability'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BCardBody,
    BForm,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    contract: {
      type: Object,
      default: () => ({ is_has_plan: false }),
      required: true,
    },
  },
  data() {
    return {
      isHasPlan: null,
    }
  },
  computed: {
    canChangeHasPlan() {
      if (this.contract.status !== 'contract_signed') return false

      const userInThisTeam = getUserData().department_id === this.contract.department_id

      return ability().can('manage', 'Settings')
        || (userInThisTeam && ability().can('VERIFICATION_CONTRACT', 'system'))
        || ability().can('update_contract_has_plan', 'Contract')
    },
  },
  watch: {
    'contract.is_has_plan': {
      handler(n) {
        if (n === 'false' || n === '0') this.isHasPlan = false
        else this.isHasPlan = !!n
      },
      immediate: true,
    },
  },
  methods: {
    handleChangeIsHasPlan(val) {
      if (this.canChangeHasPlan) {
        this.$emit('update-has-plan', val)
      } else {
        this.isHasPlan = !val
        this.$toastr('Trạng thái HS/HĐ hiện tại chưa đúng hoặc người dùng không có quyền thay đổi', 'warning')
      }
    },
  },
}
</script>
<style lang="scss">
.row-label {
  min-width: 90px;
  margin-right: .25rem;
}
.row-value {
  font-weight: bold;
}
.old-creator {
  color: #7367f0;
  position: absolute;
  top: .25rem;
  right: .5rem;
}
</style>
